import React from "react";
import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer_text"></div>

    </footer>
  )
};

export default Footer;
